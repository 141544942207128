import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { BsTelephonePlusFill } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";
import { BsFilePerson } from "react-icons/bs";
import { Link } from "react-router-dom";
import banner1 from './img/carousel1.webp';
import banner2 from './img/carousel2.webp';
import banner3 from './img/banner3.webp';
import contact from './img/contact.png';
import abouth from './img/abouh.webp';
import service1 from './img/service1.webp';
import service2 from './img/service2.webp';
import service3 from './img/service3.webp';
import product1 from './img/product1.webp';
import product2 from './img/product2.webp';
import product3 from './img/product3.webp';
import product4 from './img/product4.webp';
import { Triangle } from 'react-loader-spinner';


const options = {
    loop: true,
    lazyLoad: true,
    items: 4,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    responsive: {
        0: {
            items: 1,
            stagepadding: 10,
        },
        600: {
            items: 2
        },
        1000: {
            items: 3
        }
    }
};

const sliderArr = [banner1, banner2, banner3]

const options2 = {
    responsiveclass: true,
    items: 1,
    smartSpeed: 850,
    touchDrag: false,
    mouseDrag: false,
    freeDrag: false,
    pullDrag: false,
    nav: true,
    autoplay: true,
    loop: false,
    dots: false,
    margin: 10,

};


const Main = () => {
    return (
        <div>
        
            <div className="container-fluid header bg-white p-0" >
                <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
                    <div className="col-md-6 p-lg-5 p-4 mt-lg-5 " style={{ background: "#f7fbff" }}>
                        <h1 className="display-5 animated fadeIn mb-4">Building <span className="text-prim"> your future</span>  with passion</h1>
                        <p className="animated fadeIn mb-4 pb-2 p-black"> we stand as a beacon of innovation and reliability in the dynamic world of Piling & Foundation Solutions. Having experience  of 10 Years, our company has swiftly risen to prominence, embodying a commitment to excellence, safety, and cutting-edge solutions in the construction industry.</p>
                        
                    </div>
                    <div className="col-md-6 animated fadeIn banner d-flex align-items-center justify-content-center ">
                        {sliderArr.length === 0 ? <Triangle
                            visible={true}
                            height="80"
                            width="80"
                            color="#00b98e"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        /> : <OwlCarousel className="owl-theme" {...options2} >


                            {sliderArr.map((item) => {
                                return (
                                  
                                    <div className="owl-carousel-item">
                                        <img src={item} alt="blog"></img>
                                    </div>
                                  
                                );
                            })}

                         


                        </OwlCarousel>}


                    </div>
                </div>
            </div>
          



            <div className="container-xxl py-5" style={{ borderBottomRightRadius: '10%', background: 'aliceblue', borderBottomLeftRadius: "10%" }}>
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 " data-wow-delay="0.1s">
                            <div className="about-img position-relative overflow-hidden p-5 pe-0">
                                <img className='w-100' alt='blog'  src={abouth}></img>  
                            </div>
                        </div>
                        <div className="col-lg-6 " data-wow-delay="0.5s">
                            <h1 className="mb-3"> <span style={{ color: '#00b98e', textTransform: 'uppercase', fontWeight: "bold" }}>Introducing </span> <br></br>Stay Safe GUARD <span style={{ color: '#ff623c' }}>LTD.</span></h1>
                            <p className="mb-3 p-black">At STAY SAFE <span style={{ color: '#ff623c' }}>GUARD</span> LIMITED, we stand as a beacon of innovation and reliability in the dynamic world of Piling & Foundation Solutions. Having experience  of 10 Years, our company has swiftly risen to prominence, embodying a commitment to excellence, safety, and cutting-edge solutions in the construction industry.</p>
                            <h4 style={{ color: '#00b98e', }}><i className="fa fa-check text-prim "></i>Stay Safe Guard Limited: Pioneering Excellence in Piling Construction </h4>
                            <p className="p-black"><i className="fa fa-check text-prim "></i>Welcome to Stay Safe Guard Limited, where innovation meets reliability in the dynamic world of piling construction.since  10 Years, we take pride in our commitment to safety, quality, and cutting-edge solutions that redefine the standards of the industry.</p>
                            <Link className="btn btn-primary py-3 px-5" to="/about">Read more</Link>

                        </div>
                    </div>
                </div>
            </div>


            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-0 gx-5 align-items-end">
                        <div className="col-lg-8">
                            <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                                <h1 className="mb-3"> <span style={{ color: '#00b98e' }}>Our</span> Service:- </h1>
                                <p className="p-black">At STAY SAFE <span style={{ color: '#ff623c' }}>GUARD LIMITED,</span> we are committed to delivering excellence in fabrication, construction, and reinforcement services. Our focus on precision, quality materials, and innovative solutions sets us apart, ensuring that your projects are not only structurally sound but also meet the highest standards of safety and functionality.</p>
                            </div>
                        </div>

                    </div>


                    <div className="container services">
                        <OwlCarousel classNameName="owl-theme "
                            {...options}>


                            <div className="service-item rounded overflow-hidden ">
                                <div className="position-relative overflow-hidden">

                                    <img className='w-100' src={service1} alt="blog"></img>
                                    <div className="bg-white rounded-top text-prim position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Fabrication</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className="mb-3" style={{ color: '#ff623c' }}>Fabrication Work</h5>
                                    <p className="p-black">Our skilled product
                                        specializes in high-quality fabrication work, creating custom solutions
                                        to meet a diverse range of project requirements. From intricate components
                                        to robust structures, we excel in delivering fabrication services tailored to your needs. </p>
                                </div>

                            </div>

                            <div className="service-item rounded overflow-hidden ">
                                <div className="position-relative overflow-hidden">
                                    <img className='w-100' src={service2} alt="blog"></img>

                                    <div className="bg-white rounded-top text-prim position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Metal Stair</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className=" mb-3" style={{ color: '#ff623c' }}>Metal Stair Construction:</h5>
                                    <p className="p-black">Elevate your spaces with our expertise in designing and
                                        constructing metal stairs. Our product ensures precision in
                                        fabrication and installation, creating functional and aesthetically
                                        pleasing metal staircases that complement the design of your building.</p>
                                </div>

                            </div>

                            <div className="service-item rounded overflow-hidden ">
                                <div className="position-relative overflow-hidden">
                                    <img className='w-100' src={service3} alt="blog"></img>
                                    <div className="bg-white rounded-top text-prim position-absolute start-0 bottom-0 mx-4 pt-1 px-3">Drop Structures</div>
                                </div>
                                <div className="p-4 pb-0">
                                    <h5 className=" mb-3" style={{ color: '#ff623c' }}>Reinforcement in Drop Structures</h5>
                                    <p className="p-black">Enhance the strength
                                        and durability of drop structures with our reinforcement solutions. We employ advanced techniques and quality materials
                                        to reinforce structures effectively, ensuring they withstand the
                                        test of time and environmental factors.</p>
                                </div>

                            </div>
                        </OwlCarousel>




                        <div className="col-12 text-center Up mt-3" data-wow-delay="0.1s">
                            <Link to="/service" className="btn btn-primary py-3 px-5" >Browse More Services</Link>
                        </div>
                    </div>



                </div>
            </div>

            <div className="container-xxl py-5" style={{ background: "aquamarine", borderTopLeftRadius: "10%", borderTopRightRadius: "10%" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                                <h1 className="mb-3" style={{ color: "#f00" }}> List of <span style={{ color: '#00b98e' }}>products  </span> </h1>
                                <p className="p-black">At STAY SAFE <span style={{ color: '#ff623c' }}>GUARD LIMITED,</span> we are committed to delivering excellence in fabrication, construction, and reinforcement services. Our focus on precision, quality materials, and innovative solutions sets us apart, ensuring that your projects are not only structurally sound but also meet the highest standards of safety and functionality.</p>
                            </div>
                        </div>
                    </div>
                    <div className="products">
                        <OwlCarousel  {...options}>


                            <div className="product-item rounded overflow-hidden">
                                <div className="position-relative">
                                    <img className='w-100' src={product1} alt="blog"></img>

                                </div>
                                <div className="text-center p-4 mt-3">
                                    <h5 className="fw-bold mb-2">Concrete Piles: </h5>
                                    <p className="mb-1">- Pre-cast concrete piles</p>
                                    <p className="mb-0">- Cast-in-place concrete piles</p>
                                </div>
                            </div>

                            <div className="product-item rounded overflow-hidden">
                                <div className="position-relative">
                                    <img className='w-100' src={product2} alt="blog"></img>

                                </div>
                                <div className="text-center p-4 mt-3">
                                    <h5 className="fw-bold mb-2">Timber Piles:  </h5>
                                    <p className="mb-1">- Round timber piles</p>
                                    <p className="mb-0">- Square timber piles</p>
                                </div>
                            </div>

                            <div className="product-item rounded overflow-hidden">
                                <div className="position-relative">
                                    <img className='w-100' src={product3} alt="blog"></img>

                                </div>
                                <div className="text-center p-4 mt-3">
                                    <h5 className="fw-bold mb-2">Helical Piles</h5>
                                    <p className="mb-1">- Helical screw piles</p>
                                    <p className="mb-0">- Helical anchor piles</p>
                                </div>
                            </div>
                            <div className="product-item rounded overflow-hidden">
                                <div className="position-relative">
                                    <img className='w-100' src={product4} alt="blog"></img>

                                </div>
                                <div className="text-center p-4 mt-3">
                                    <h5 className="fw-bold mb-2">Steel Piles:  </h5>
                                    <p className="mb-0">- Sheet piles</p>
                                    <p className="mb-0">- Pipe piles</p>
                                    <p className="mb-1">- H-section steel piles</p>
                                </div>
                            </div>



                        </OwlCarousel>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center Up mt-3" data-wow-delay="0.1s">
                            <Link to="/" className="btn btn-secondary py-3 px-5" style={{ background: '#ff623c' }}>Browse More Products</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contact'>

                <div className="container-xxl py-5">
                    <div className="container">
                        <div className=" rounded ">
                            <div className=" contactbg rounded " style={{ border: "2px solid rgb(127 255 212 / 11%)" }}>
                                <div className="row g-4 align-items-center">
                                    <div className="col-lg-5 contact-img   h-100" data-wow-delay="0.1s" style={{background:"#41ffff"}}>
                                        <img
                                          alt='blog'
                                            className='w-100 h-100'
                                            src={contact}
                                            style={{ objectFit: 'cover' , }}
                                        ></img>
                                    </div>
                                    <div className="col-lg-7 " data-wow-delay="0.5s">
                                        <div >
                                            <h1 className="mb-2 text-white">Contact <span style={{ color: '#ff623c' }}>US</span> </h1>
                                            <h5 className=" text-white">On Time Builders: <span style={{ color: '#ff623c' }}>Building Relationships, </span> Building Trust</h5>
                                            <h5 className=" text-white">Introducing <span style={{ color: '#ff623c' }}>STAY SAFE GUARD LIMITED: </span>   Pioneering Excellence in Piling & Foundation Solutions</h5>
                                           
                                            <h6 className='text-white my-2 p-1 d-flex align-items-start'>
                                            <BsFilePerson style={{ color: "#00ffc4", fontSize: 25, marginRight: 6 }} />

                                                Contact Person :  Mr Fred
                                            </h6>
                                            <h6 className='text-white my-2 p-1 d-flex align-items-start'>
                                                <BsTelephonePlusFill style={{ color: "#00ffc4", fontSize: 25, marginRight: 6 }} />

                                                Contact no :  +44 7438178457 
                                            </h6>
                                            <h6 className='text-white my-2 p-1 d-flex align-items-start'>
                                                <AiFillHome style={{ color: "#00ffc4", fontSize: 25, marginRight: 6 }} />

                                                Correspondence address :204 Rookery Road, Handsworth, Birmingham, England, B21 9PY

                                            </h6>
                                            <h6 className='text-white my-2 p-1 d-flex align-items-start'>
                                                <AiFillMail style={{ color: "#00ffc4", fontSize: 25, marginRight: 6 }} /> 

                                              <a href='mailto:sales@staysafeguardlimited.co.uk'style={{ color: "#fff", fontSize: "1rem !Important",}}>  sales@staysafeguardlimited.co.uk</a>




                                            </h6>

                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>







        </div>
    )
}

export default Main;




