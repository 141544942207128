import React from 'react'
import aboutb from './img/aboutbanner.webp'
import abouth from './img/abouh.webp'
import abouth1 from './img/abouth1.webp'
import { Link } from "react-router-dom";


const About = () => {
    return (
        <div>
            <div className="container-xxl bg-white p-0">
                <div className="container-fluid header bg-white p-0">
                    <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
                        <div className="col-md-6 p-5 mt-lg-1" style={{ background: "#f7fbff" }}>
                            <h1 className="display-5 animated fadeIn mb-2"> <span style={{ color: '#00b98e' }}>About</span> <span style={{ color: '#0E2E50' }}>US</span></h1>
                            <nav aria-label="breadcrumb animated fadeIn mb-4">
                                <ol className="breadcrumb text-uppercase">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item text-body active" aria-current="page"><Link to="/about">about</Link></li>
                                </ol>
                            </nav>
                            <h3 style={{ color: '#000' }}>   <span style={{ color: '#ff623c' }}>Welcome </span>to Stay Safe Guard Limited,</h3>
                            <p className="p-black">
                                where innovation meets reliability in the dynamic world of piling construction. we take pride in our commitment to safety, quality, and cutting-edge solutions that redefine the standards of the industry.
                            </p>
                            <h4 style={{ color: '#000', }}><i className="fa fa-check text-prim "></i>Stay Safe Guard Limited: Pioneering Excellence in Piling Construction since 10 years</h4>




                        </div>
                        <div className="col-md-6 animated fadeIn">
                            <img  src={aboutb}  alt="blog" className='w-100'></img>
                        </div>
                    </div>
                </div>







                <div className="container-xxl py-5" style={{ background: "aliceblue" }}>
                    <div className="container">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                <div className="about-img position-relative overflow-hidden p-5 pe-0">
                              
                                <img  className='w-100'
                                    src={abouth} alt='blog'></img>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <h1 className="mb-3"> <span style={{ color: '#00b98e', textTransform: 'uppercase', fontWeight: "bold" }}>Introducing </span> <br></br>Stay Safe GUARD <span style={{ color: '#ff623c' }}>LTD.</span></h1>
                                <p className="mb-3 p-black">At STAY SAFE <span style={{ color: '#ff623c' }}>GUARD</span> LIMITED, we stand as a beacon of innovation and reliability in the dynamic world of Piling & Foundation Solutions. Having experience  of 10 Years, our company has swiftly risen to prominence, embodying a commitment to excellence, safety, and cutting-edge solutions in the construction industry.</p>
                                <h4 className="mb-2"> <span style={{ color: '#00b98e', textTransform: 'uppercase', fontWeight: "bold" }}>Our Mission: </span></h4>
                                <p className="p-black"><i className="fa fa-check text-prim "></i>At Stay Safe Guard Limited, our mission is clear - to deliver unparalleled piling construction services that ensure the stability and durability of structures while prioritizing the safety of our workforce and the environment. We aim to be at the forefront of technological advancements, consistently raising the bar for excellence in the construction sector.</p>


                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <h4 className="mb-2"> <span style={{ color: '#0E2E50', textTransform: 'uppercase', fontWeight: "bold" }}>Unwavering<span style={{ color: '#ff623c' }}> Commitment</span> to Safety:  </span> </h4>
                                <p className="mb-4 p-black">Safety is not just a priority; it's a core value embedded in our company culture. We prioritize the well-being of our team members and stakeholders, implementing rigorous safety protocols and training programs to create a secure working environment. Our goal is to not only meet industry safety standards but to exceed them, ensuring that every project is executed with the utmost care and precaution.</p>
                                <h4 className="mb-2" style={{ color: '#0E2E50', textTransform: 'uppercase', fontWeight: "bold" }}><i className="fa fa-check text-prim "></i><span style={{ color: '#ff623c' }}>Quality</span> that Speaks Volumes: </h4>
                                <p className="p-black mb-4"><i className="fa fa-check text-prim "></i>Stay Safe Guard Limited is synonymous with quality. We employ the latest technologies and methodologies to guarantee the precision and reliability of our piling solutions. Our team of skilled professionals, coupled with state-of-the-art equipment, ensures that each project is executed with unmatched precision, adhering to international quality standards.</p>
                                <h4 className="mb-2" style={{ color: '#0E2E50', textTransform: 'uppercase', fontWeight: "bold" }}><i className="fa fa-check text-prim "></i>Innovation at the <span style={{ color: '#ff623c' }}>Core:</span> </h4>
                                <p className="p-black mb-4"><i className="fa fa-check text-prim "></i>In a rapidly evolving industry, Stay Safe Guard Limited stands as a beacon of innovation. We embrace the latest advancements in construction technology, continuously exploring new methods and materials to optimize efficiency and performance. Our commitment to innovation allows us to tackle complex challenges with creative solutions, setting new benchmarks for the industry.</p>

                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                <div className="about-img position-relative overflow-hidden p-5 pe-0">
                                  
                                <img  className='w-100'
                                    src={abouth1} alt='blog'></img>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>








            </div>

        </div>

    )
}

export default About;


