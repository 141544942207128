import React from "react";
import serviceb from "./img/serviceb.jpg";
import servicem1 from "./img/service1m.webp";
import servicem2 from "./img/service2m.webp";
import servicem3 from "./img/service3m.webp";
import servicem4 from "./img/service4m.webp";
import servicem5 from "./img/service5m.webp";
import { Link } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from "lightbox-react";
import "lightbox-react/style.css";

import F1 from "./img/febrication/f (1).webp";
import F2 from "./img/febrication/f (2).webp";
import F3 from "./img/febrication/f (3).webp";
import F4 from "./img/febrication/f (4).webp";
import F5 from "./img/febrication/f (5).webp";
import F6 from "./img/febrication/f (6).webp";

import b1 from "./img/febrication/b (1).webp";
import b2 from "./img/febrication/b (2).webp";
import b3 from "./img/febrication/b (3).webp";
import b4 from "./img/febrication/b (4).webp";
import b5 from "./img/febrication/b (5).webp";
import b6 from "./img/febrication/b (6).webp";

import s1 from "./img/stairs/s (1).webp";
import s2 from "./img/stairs/s (2).webp";
import s3 from "./img/stairs/s (3).webp";
import s4 from "./img/stairs/s (4).webp";
import s5 from "./img/stairs/s (5).webp";
import s6 from "./img/stairs/s (6).webp";

import e1 from "./img/stairs/e (1).webp";
import e2 from "./img/stairs/e (2).webp";
import e3 from "./img/stairs/e (3).webp";
import e4 from "./img/stairs/e (4).webp";
import e5 from "./img/stairs/e (5).webp";
import e6 from "./img/stairs/e (6).webp";

import R1 from "./img/reinforcement/1.webp";
import R2 from "./img/reinforcement/2.webp";
import R3 from "./img/reinforcement/3.webp";
import R4 from "./img/reinforcement/4.webp";
import R5 from "./img/reinforcement/5.webp";
import R6 from "./img/reinforcement/6.webp";
import { ColorRing, Triangle } from "react-loader-spinner";

const imagesFebrication = [F1, F2, F3, F4, F5, F6];
const imagesElectricGates = [e1, e2, e3, e4, e5, e6];
const imagesStairs = [s1, s4, s2, s3, s5, s6];
const imagesBuilding = [b1, b4, b2, b3, b5, b6];
const imagesR = [R1, R2, R3, R4, R5, R6];

const Service = () => {
  // febrication
  const [openFebr, setOpenFebr] = React.useState(false);
  const [fIndex, setFIndex] = React.useState(0);
  const lightBoxFebri = (state, sIndex) => {
    setOpenFebr(state);
    setFIndex(sIndex);
  };

  // stairs
  const [openStairs, setOpenStairs] = React.useState(false);
  const [sIndex, setSIndex] = React.useState(0);
  const lightBoxStairs = (state, sIndex) => {
    setOpenStairs(state);
    setSIndex(sIndex);
  };

  // electric gates
  const [openE, setOpenE] = React.useState(false);
  const [eIndex, setEIndex] = React.useState(0);
  const lightBoxElectric = (state, sIndex) => {
    setOpenE(state);
    setEIndex(sIndex);
  };

  //reinforcement  building
  const [openR, setOpenR] = React.useState(false);
  const [rIndex, setRIndex] = React.useState(0);
  const lightBoxReinforcement = (state, sIndex) => {
    setOpenR(state);
    setRIndex(sIndex);
  };

  //REIFICATION  building
  const [openB, setOpenB] = React.useState(false);
  const [bIndex, setBIndex] = React.useState(0);
  const lightBoxBuilding = (state, sIndex) => {
    setOpenB(state);
    setBIndex(sIndex);
  };

  const bannerArr = [serviceb]



  return (
    <div>
      <div className="bg-white p-0">
        <div className="container-fluid header bg-white p-0">
          <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
            <div
              className="col-md-6 p-5 mt-lg-0"
              style={{ background: "#f7fbff" }}
            >
              <h1 className="display-5 animated fadeIn mb-2">
                {" "}
                <span style={{ color: "#00b98e" }}>Our</span>{" "}
                <span style={{ color: "#0E2E50" }}>Services</span>
              </h1>
              <nav aria-label="breadcrumb animated fadeIn mb-4">
                <ol className="breadcrumb text-uppercase">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-body active"
                    aria-current="page"
                  >
                    <Link to="/services">Services</Link>
                  </li>
                </ol>
              </nav>
              <h3 style={{ color: "#000" }}>
                {" "}
                At <span style={{ color: "#ff623c" }}>STAY SAFE</span>GUARD
                LIMITED,
              </h3>
              <p className="p-black">
                we are committed to delivering excellence in fabrication,
                construction, and reinforcement services. Our focus on
                precision, quality materials, and innovative solutions sets us
                apart, ensuring that your projects are not only structurally
                sound but also meet the highest standards of safety and
                functionality.
              </p>
              <h4 style={{ color: "#000" }}>
                <i className="fa fa-check text-prim "></i>Stay Safe Guard
                Limited: Pioneering Excellence in Piling Construction since 10 years
              </h4>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">

              {bannerArr.length === 0 ? <Triangle
                visible={true}
                height="80"
                width="80"
                color="#00b98e"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
              /> :

                <img src={serviceb} alt="blog" style={{width:"100%"}}></img>
              }
            </div>
          </div>
        </div>

        <div
          className=" py-5"
          style={{ borderTopLeftRadius: "10%", borderTopRightRadius: "10%" }}
        >
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 " >
                <div className="service-img overflow-hidden p-5 pe-0">
                <img  className="w-100"
                    src={servicem1} alt="blog"></img>
                </div>
              </div>
              <div className="col-lg-6 " >
                <h1 className="mb-3">
                  <span
                    style={{
                      color: "#00b98e",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    fabrication
                  </span>{" "}
                  <span style={{ color: "#ff623c" }}> Work:</span>
                </h1>
                <p className="mb-3 p-black">
                  {" "}
                  - Our skilled team specializes in high-quality{" "}
                  <span style={{ color: "#ff623c" }}>
                    fabrication work
                  </span>{" "}
                  creating custom solutions to meet a diverse range of project
                  requirements. From intricate components to robust structures,
                  we excel in delivering fabrication services tailored to your
                  needs.
                </p>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  columnsCount={3}
                >
                  {
                    imagesFebrication.length === 0 ? <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> :

                      <Masonry>
                        {imagesFebrication.map((image, i) => (
                          <div style={{ padding: 5 }}>
                            <img
                              style={{ borderRadius: 10 }}
                              key={i}
                             alt="blog"
                              className="w-100"
                              src={image}
                              onClick={() => lightBoxFebri(true, i)}
                            />
                          </div>
                        ))}
                      </Masonry>
                  }
                </ResponsiveMasonry>
                {openFebr && (
                  <Lightbox
                    mainSrc={imagesFebrication[fIndex]}
                    nextSrc={
                      imagesFebrication[(fIndex + 1) % imagesFebrication.length]
                    }
                    prevSrc={
                      imagesFebrication[
                      (fIndex + imagesFebrication.length - 1) %
                      imagesFebrication.length
                      ]
                    }
                    onCloseRequest={() => setOpenFebr(false)}
                    onMovePrevRequest={() =>
                      setFIndex(
                        (fIndex + imagesFebrication.length - 1) %
                        imagesFebrication.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setFIndex((fIndex + 1) % imagesFebrication.length)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className=" py-5"
          style={{
            background: "aquamarine",
            borderTopLeftRadius: "10%",
            borderTopRightRadius: "10%",
          }}
        >
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 " data-wow-delay="0.5s">
                <h1 className="mb-3">
                  {" "}
                  <span
                    style={{
                      color: "#00b98e",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Metal Stair{" "}
                  </span>{" "}
                  <span style={{ color: "#ff623c" }}>Construction</span>
                </h1>
                <p className="mb-3 p-black">
                  {" "}
                  - Elevate your spaces with our expertise in designing and
                  constructing{" "}
                  <span style={{ color: "#ff623c" }}>metal stairs</span> . Our
                  team ensures precision in fabrication and installation,
                  creating functional and aesthetically pleasing metal
                  staircases that complement the design of your building.
                </p>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  columnsCount={3}
                >
                  {
                    imagesStairs.length === 0 ? <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> :

                      <Masonry>

                        {imagesStairs.map((image, i) => (
                          <div style={{ padding: 5 }}>
                            <img
                              style={{ borderRadius: 10 }}
                              key={i}
                             alt="blog"
                              className="w-100"
                              src={image}
                              onClick={() => lightBoxStairs(true, i)}
                            />
                          </div>
                        ))}
                      </Masonry>
                  }
                </ResponsiveMasonry>
                {openStairs && (
                  <Lightbox
                    mainSrc={imagesStairs[sIndex]}
                    nextSrc={imagesStairs[(sIndex + 1) % imagesStairs.length]}
                    prevSrc={
                      imagesStairs[
                      (sIndex + imagesStairs.length - 1) % imagesStairs.length
                      ]
                    }
                    onCloseRequest={() => setOpenStairs(false)}
                    onMovePrevRequest={() =>
                      setSIndex(
                        (sIndex + imagesStairs.length - 1) % imagesStairs.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setSIndex((sIndex + 1) % imagesStairs.length)
                    }
                  />
                )}
              </div>
              <div className="col-lg-6 " data-wow-delay="0.1s">
                <div className="service-img2 overflow-hidden p-5 pe-0">
                <img  className="w-100"
                    src={servicem2} alt="blog"></img>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className=" py-5"
          style={{ borderTopLeftRadius: "10%", borderTopRightRadius: "10%" }}
        >
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 " data-wow-delay="0.1s">
                <div className="service-img overflow-hidden p-5 pe-0">
                <img  className="w-100"
                    src={servicem3} alt="blog"></img>

                </div>
              </div>
              <div className="col-lg-6 " data-wow-delay="0.5s">
                <h1 className="mb-3">
                  {" "}
                  <span
                    style={{
                      color: "#00b98e",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Reinforcement{" "}
                  </span>{" "}
                  <span style={{ color: "#ff623c" }}> in Drop Structures:</span>
                </h1>
                <p className="mb-3 p-black">
                  {" "}
                  Enhance the strength and durability of{" "}
                  <span style={{ color: "#ff623c" }}>
                    drop structures{" "}
                  </span>{" "}
                  with our reinforcement solutions. We employ advanced
                  techniques and quality materials to reinforce structures
                  effectively, ensuring they withstand the test of time and
                  environmental factors.
                </p>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  columnsCount={3}
                >
                  {
                    imagesR.length === 0 ? <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> :
                      <Masonry>
                        {imagesR.map((image, i) => (
                          <div style={{ padding: 5 }}>
                            <img
                              style={{ borderRadius: 10 }}
                              key={i}
                             alt="blog"
                              className="w-100"
                              src={image}
                              onClick={() => {
                                lightBoxReinforcement(true, i);
                              }}
                            />
                          </div>
                        ))}
                      </Masonry>
                  }
                </ResponsiveMasonry>
                {openR && (
                  <Lightbox
                    mainSrc={imagesR[rIndex]}
                    nextSrc={imagesR[(rIndex + 1) % imagesR.length]}
                    prevSrc={
                      imagesR[(rIndex + imagesR.length - 1) % imagesR.length]
                    }
                    onCloseRequest={() => setOpenR(false)}
                    onMovePrevRequest={() =>
                      setRIndex((rIndex + imagesR.length - 1) % imagesR.length)
                    }
                    onMoveNextRequest={() =>
                      setRIndex((rIndex + 1) % imagesR.length)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className=" py-5"
          style={{
            background: "#ff623c",
            borderTopLeftRadius: "10%",
            borderTopRightRadius: "10%",
          }}
        >
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 " data-wow-delay="0.5s">
                <h1 className="mb-3">
                  {" "}
                  <span
                    style={{
                      color: "#fff",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Supply of Steel{" "}
                  </span>{" "}
                  <span style={{ color: "#1a584a" }}>
                    <br></br>RJS Electric Gates
                  </span>
                </h1>
                <p className="mb-3 p-black text-white">
                  {" "}
                  Access cutting-edge technology with our{" "}
                  <span style={{ color: "#1a584a" }}>
                    supply of Steel RJS Electric Gates.{" "}
                  </span>{" "}
                  . Our team ensures precision in fabrication and installation,
                  creating functional and aesthetically pleasing metal
                  staircases that complement the design of your building.
                </p>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  columnsCount={3}
                >
                   {imagesElectricGates.length === 0 ? <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> :
                  <Masonry>
                    {imagesElectricGates.map((image, i) => (
                      <div style={{ padding: 5 }}>
                        <img
                          style={{ borderRadius: 10 }}
                          key={i}
                         alt="blog"
                          className="w-100"
                          src={image}
                          onClick={() => lightBoxElectric(true, i)}
                        />
                      </div>
                    ))}
                  </Masonry>
}
                </ResponsiveMasonry>
               {openE && (
                  <Lightbox
                    mainSrc={imagesElectricGates[eIndex]}
                    nextSrc={imagesElectricGates[(eIndex + 1) % imagesElectricGates.length]}
                    prevSrc={
                      imagesElectricGates[(eIndex + imagesElectricGates.length - 1) % imagesElectricGates.length]
                    }
                    onCloseRequest={() => setOpenE(false)}
                    onMovePrevRequest={() =>
                      setEIndex((eIndex + imagesElectricGates.length - 1) % imagesElectricGates.length)
                    }
                    onMoveNextRequest={() =>
                      setEIndex((eIndex + 1) % imagesElectricGates.length)
                    }
                  />
                )}
              
          

              </div>
              <div className="col-lg-6 " data-wow-delay="0.1s">
                <div className="service-img2 overflow-hidden p-5 pe-0">
              
                  <img  className="w-100"
                    src={servicem4} alt="blog"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className=" py-5"
          style={{ borderTopLeftRadius: "10%", borderTopRightRadius: "10%" }}
        >
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 " data-wow-delay="0.1s">
                <div className="service-img overflow-hidden p-5 pe-0">
                <img  className="w-100"
                    src={servicem5} alt="blog"></img>
                </div>
              </div>
              <div className="col-lg-6 " data-wow-delay="0.5s">
                <h1 className="mb-3">
                  {" "}
                  <span
                    style={{
                      color: "#00b98e",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Reification for{" "}
                  </span>{" "}
                  <span style={{ color: "#ff623c" }}>
                    {" "}
                    Building Foundations:
                  </span>
                </h1>
                <p className="mb-3 p-black">
                  {" "}
                  Entrust us with the reification process for{" "}
                  <span style={{ color: "#ff623c" }}>
                    building foundations,{" "}
                  </span>{" "}
                  where we meticulously craft and solidify the foundation using
                  the required rebar reinforcement. Our comprehensive approach
                  ensures that the foundation is not just strong but tailored to
                  meet the specific needs of your construction project.
                </p>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  columnsCount={3}
                >
                    {
                   imagesBuilding.length === 0 ? <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> :
                  <Masonry>
                    {imagesBuilding.map((image, i) => (
                      <div style={{ padding: 5 }}>
                        <img
                          style={{ borderRadius: 10 }}
                          key={i}
                         alt="blog"
                          className="w-100"
                          src={image}
                          onClick={() => lightBoxBuilding(true, i)}
                        />
                      </div>
                    ))}
                  </Masonry>
}
                </ResponsiveMasonry>
                {openB && (
                  <Lightbox
                    mainSrc={imagesBuilding[bIndex]}
                    nextSrc={
                      imagesBuilding[(bIndex + 1) % imagesBuilding.length]
                    }
                    prevSrc={
                      imagesBuilding[
                      (bIndex + imagesBuilding.length - 1) %
                      imagesBuilding.length
                      ]
                    }
                    onCloseRequest={() => setOpenB(false)}
                    onMovePrevRequest={() =>
                      setBIndex(
                        (bIndex + imagesBuilding.length - 1) %
                        imagesBuilding.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setBIndex((bIndex + 1) % imagesBuilding.length)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
