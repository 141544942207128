import React from 'react'
const Terms = () => {
    return (
        <div>
            <div className="container   p-2">
                <div className='mt-4 mb-4'>
                    <h2>Terms and Conditions for <span style={{ color: "#08bb92", fontWeight: "600" }}>Stay Safe Guard Limited,</span> </h2>

                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>1. Acceptance of Terms</h4>
                        By engaging with Construction Piles and Gates Company (referred to as "the Company"), you acknowledge and agree to be bound by these Terms and Conditions. These terms constitute a legally binding agreement between you and the Company.
                    </div>
                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>2. Scope of Services</h4>
                        The Company agrees to provide construction piles and gates services as agreed upon in writing with the client. Any additional services requested beyond the agreed scope may incur additional charges.
                    </div>
                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>3. Pricing and Payments</h4>
                        Payment terms will be as agreed upon in the contract or proposal. Payments are due within the specified timeframe. Late payments may incur penalties or interest charges. The Company reserves the right to withhold services until outstanding payments are settled.
                    </div>
                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>4. Project Timeline</h4>
                        The Company will make reasonable efforts to adhere to agreed project timelines. However, unforeseen circumstances such as weather conditions or material shortages may impact project schedules. The Company will communicate any delays and endeavor to mitigate their impact.
                    </div>
                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>5. Changes and Amendments</h4>
                        Any changes or amendments to the project scope must be agreed upon in writing by both parties. Additional charges may apply for changes that affect project costs or timelines.
                    </div>
                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>6. Quality Assurance</h4>
                        The Company will strive to deliver high-quality workmanship and materials in accordance with industry standards and client specifications. Any defects or issues with the work performed must be reported promptly to the Company for resolution.
                    </div>
                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>7. Health and Safety</h4>
                        The Company is committed to maintaining a safe working environment for its employees and subcontractors. Clients are responsible for ensuring site safety and compliance with relevant health and safety regulations.
                    </div>
                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>8. Liability and Insurance</h4>
                        The Company carries appropriate insurance coverage for its operations. While every effort will be made to minimize risks, the Company shall not be held liable for any damages, losses, or injuries arising from the performance of its services, except in cases of proven negligence or willful misconduct.
                    </div>
                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>9. Termination of Services</h4>
                        Either party may terminate the agreement with written notice if the other party breaches its obligations and fails to remedy the breach within a reasonable timeframe. Upon termination, the client shall pay for any services rendered up to the termination date.
                    </div>
                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>10. Governing Law</h4>
                        These Terms and Conditions shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].
                    </div>
                    <div className='my-3'>
                        <h4 className=" mb-1" style={{ color: "#ff623c" }}>11. Entire Agreement</h4>
                        These Terms and Conditions constitute the entire agreement between the parties and supersede all prior agreements and understandings, whether written or oral, relating to the subject matter herein.
                        By engaging with Construction Piles and Gates Company, you signify your acceptance of these Terms and Conditions. If you have any questions or concerns, please contact us for clarification before proceeding.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms;


