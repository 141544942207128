import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='container-fluid p-3' style={{background :"#000"}}>
      <div className=' row' >
        <div className='col-lg-8 col-md-10 col-12'>
     <p className='mb-0' style={{color :"#fff"}}>COPYRIGHT © 2024Stay Safe, All rights Reserved Magna Opus Software Services</p>
        </div>
        <div className='col-lg-4 col-md-2 d-flex justify-content-lg-end col-12'>   
      <p className= 'mb-0' style={{color :"#fff"}}><Link to="/terms">Terms & Conditions</Link></p>
    </div>
 
      </div>
    </div>
  )
}

export default Footer;
