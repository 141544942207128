import React,{useState,useEffect} from "react";
import './App.css';
import { Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Main from "./components/main";
import About from "./components/about";
import Service from "./components/service";
import Footer from "./components/footer";
import Terms from "./components/terms";
import './components/Animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';




const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await demoAsyncCall();
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once on mount

  function demoAsyncCall() {
    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
  }

  if (loading) {
    return <div className="loader"></div>;
  } 
  return (
    <div className="app">
       <Header/> 
      <Routes>
        <Route exact path="/" Component={Main} />
        <Route  path="/about" Component={About} />
        <Route  path="/service" Component={Service} />
        <Route  path="/terms" Component={Terms} />
        
      </Routes>
       <Footer/>
      </div>
    
    
  );
};

export default App;


