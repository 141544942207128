import { Link } from "react-router-dom";
import logo from "./img/logo1.png";
import React, { useEffect } from "react";
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';


const Header = () => {
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  return (
    <div>
      {/* <div className="container-fluid nav-bar bg-transparent mx-auto px-3 header-section">
        <nav className="navbar  navbar-expand-lg bg-white navbar-light py-0 px-3">
          <Link
            className="navbar-brand d-flex align-items-center text-center"
            to="/"
          >
            <div className="icon">
              <img
                className="img-fluid"
                src={logo}
                alt="Icon"
                style={{ width: 150, objectFit: "contain" }}
              ></img>

            </div>
          </Link>

          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto">
              <Link className="nav-item nav-link active " to="/">
                home
              </Link>
              <Link className="nav-item nav-link " to="/about">
                About
              </Link>
              <Link className="nav-item nav-link " to="/service">
                Services
              </Link>

            </div>
          </div>
        </nav>
      </div> */}
        <div className="header-section nav-bar">
      <Navbar expand="lg" className="container-fluid  bg-white  px-3 ">
        <Container >
          <Navbar.Brand href="#home">   <Link
            className="navbar-brand d-flex align-items-center text-center"
            to="/"
          >
            <div className="icon">
              <img
                className="img-fluid"
                src={logo}
                alt="Icon"
                style={{ width: 150, objectFit: "contain" }}
              ></img>

            </div>
          </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="navbar-nav ms-auto">
              <NavLink className="nav-item nav-link"  to="/">
                home
              </NavLink>
              <NavLink className="nav-item nav-link " to="/about">
                About
              </NavLink>
              <NavLink className="nav-item nav-link " to="/service">
                Services
              </NavLink>
         


            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </div>
    </div>

  );
};

export default Header;
